import User from "./User";
import Establishment from "./Establishment";
import Employment from "./Employment";

export const minPresetPaymentAmount = 1;
export const maxPresetPaymentAmount = 100;
export const maxPresetPaymentAmountTRY = 200;

export default interface PaymentLink {
    id: string;
    description: string;
    qrCodeFileName?: string;
    paymentText?: string;
    presetAmount1?: number;
    presetAmount2?: number;
    presetAmount3?: number;
    user?: User;
    establishment?: Establishment;
    allowsEmployeeSelection?: boolean;
    employees?: Employment[];
    qrCodeUserName?: string;
    currencyCode: string;
    lang: string;
    payTheme: string;
}

export const numberOfPresetPaymentAmountsForPaymentLink = (
    paymentLink: PaymentLink
) => {
    let result = 0;
    if (!!paymentLink.presetAmount1) result++;
    if (!!paymentLink.presetAmount2) result++;
    if (!!paymentLink.presetAmount3) result++;
    return result;
};

export const paymentLinkHasPresetPaymentAmounts = (paymentLink: PaymentLink) =>
    numberOfPresetPaymentAmountsForPaymentLink(paymentLink) > 0;

export const paymentLinkNeedsEmployeeSelection = (paymentLink: PaymentLink) => {
    return !!paymentLink.establishment && !!paymentLink.allowsEmployeeSelection;
};

type CurrencySignMapType = Record<string, string>;

const CurrencySignMap: CurrencySignMapType = {
    "EUR": '\u20AC ',
    "GBP": '\u00A3 ',
    "TRY": '\u20BA ',
    "IDR": 'Rp ',
    "RSD": 'Din '
}

export const formatCurrencyCodeSign = (code: string | undefined) => {
    if (code && code.length > 0) {
        return CurrencySignMap[code];
    } else {
        return '';
    }
}

export const formatCurrencyCodeSignNoGap = (code: string | undefined) => {
    if (code && code.length > 0) {
        return CurrencySignMap[code].trim();
    } else {
        return '';
    }
}


// Remove the currency prefix from the amount string
export const clearCurrencyValue = (value: string, code: string | undefined): string => {
    if (code && code.length > 0) {
       if (code === 'IDR') {
           return value.slice(3)
       } else if (code === 'RSD'){
           return value.slice(4)
       } else {
           return value.slice(2)
       }
    } else {
        return value
    }
}

export const isLongCurrency = (code: string | undefined) :boolean => {
    let longCurrencyValue = false;

    if ( code === 'IDR' || code === 'RSD' ) {
        longCurrencyValue = true;
    }

    return longCurrencyValue;
}





