import {createTheme} from "@mui/material";
import mainTheme from "../mainTheme";

const ssferaPaymentTheme = createTheme({
    palette: {
        mode: "light",
        text: { primary: "#212121" },
        primary: {
            main: "#71C6C6",
        },
    },
    typography: {
        fontFamily: mainTheme.typography.fontFamily,
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontFamily: ["Arial", "sans-serif"].join(","),
                    fontSize: 15,
                    [mainTheme.breakpoints.up("sm")]: {
                        fontSize: 16,
                    },
                },
                h2: {
                    fontSize: 30,
                    fontWeight: "bold",
                    [mainTheme.breakpoints.up("sm")]: {
                        fontSize: 24,
                    },
                },
                h3: {
                    fontSize: 21,
                    fontWeight: "bold",
                    [mainTheme.breakpoints.up("sm")]: {
                        fontSize: 24,
                    },
                },
                h4: {
                    fontSize: 16,
                    fontWeight: "bold",
                },
                h5: {
                    fontSize: 14,
                    fontWeight: "bold",
                },
                caption: {
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#71C6C6",
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    border: `5px solid #71C6C6`,
                },
                rounded: {
                    borderRadius: 10,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 25,
                    "& fieldset": {
                        borderColor: "#71C6C6",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#71C6C6",
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    paddingTop: 12,
                    paddingBottom: 12,
                    fontSize: 16,
                    borderRadius: 25,
                    border: "1px solid #71C6C6",
                    height: "auto",
                    background: "#FFFFFF",
                    "&:hover": {
                        background: "#71C6C6",
                        border: "1px solid #71C6C6",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    borderRadius: 25,
                    textTransform: "none",
                    boxShadow: "none",
                    ":hover": {
                        boxShadow: "none",
                    },
                    [mainTheme.breakpoints.up("sm")]: {
                        fontSize: 20,
                    },
                },
            },
        },
    },
});

export default createTheme(ssferaPaymentTheme);
