import NoEstablishmentLogoIcon from "../../images/no-establishment-logo.svg";
import Avatar from "@mui/material/Avatar";
import {ReactComponent as SyncrosferaLogoImg} from "../../images/syncrosfera-logo-img.svg";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import React from "react";

interface SyncrosferaLogoProps {
    url?: string;
}

const SyncrosferaLogoImgStyled = styled(SyncrosferaLogoImg)`
  margin-top: -17px;
  z-index: 1;
`;


const SyncrosferaLogo = ({url}: SyncrosferaLogoProps) => {
    return (
        <Grid
            container
            direction={"column"}
            alignItems={"center"}
            sx={{
                position: "relative",
            }}
        >
            <Avatar
                variant={"circular"}
                alt={"Logo"}
                src={url || undefined}
                sx={{
                    width: 120,
                    height: 120,
                    borderWidth: "5px",
                    background: url ? "white" : "#71C6C6",
                    zIndex: "1",
                }}
            >
                <img
                    alt={"No logo"}
                    style={{width: "65%", height: "65%"}}
                    src={NoEstablishmentLogoIcon}
                />
            </Avatar>

            <SyncrosferaLogoImgStyled/>

        </Grid>
    );
};

export default SyncrosferaLogo;
